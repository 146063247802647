<template>
  <HeaderComponent />
  <div class="section flex items-center relative overflow-hidden min-h-screen px-8 z-5">
  <BottomRightTriangles />
  <div class="sm:flex w-full h-full">
      <div class="basis-1/2 ml-1 w-full">
          <!-- Page Header -->
          <DarcyHeader />
          <div class="basis-1/2 items-center justify-center w-full md:w-5/12 ml-24 z-10 block xs2:ml-40 xs2:-mt-36 sm:hidden"> <!--- Frame image only below small breakpoint -->
            <img class="max-h-[70rem] max-w-[56rem] -ml-36 h-[35rem] w-[28rem] mt-40" src="@/assets/images/svg/samuel.svg" alt="Artist's Rendering of Samuel Knowlton" />
          </div>
          <!-- Content Area: Clients -->
          <div class="grid grid-cols-2 w-full mt-8 sm:mt-56 md:mt-64 lg:mt-24 3xl:mt-28 ml-0 sm:ml-4">
            <div class="col-span-2 text-xl md:text-3xl antialiased font-semibold text-center mb-8 ml-0 sm:-ml-2 md:ml-0 mt-2 sm:mt-12 md:mt-4">What Clients Say</div>
              <div class="basis-1/2 ml-2 border-r-4 border-dotted border-darcy-blue pr-2">
                <blockquote class="font-semibold">"You made all the hard words feel easy!"</blockquote> -- Creative Project Manager, BiddyCo
              </div>
              <div class="basis-1/2 ml-2">
                 <blockquote class="font-semibold">"I really like your voiceover! It sounds really natural -- not "addy."</blockquote> -- Creative Director, FCB North America
              </div>
              <div class="basis 1/2 ml-2 mt-4">
                <blockquote class="font-semibold">"... might be my favorite company in America."</blockquote> -- Commissioner of the West Side Soccer League, on working with Sam
               
              </div>
          </div>
          <div class="w-full max-w-6xl text-center grid gap-4 grid-cols-2 lg:grid-cols-3 place-items-center mt-16 sm:mt-8 3xl:mt-8 ml-0 sm:ml-6 mb-24 sm:mb-0 md:mb-4"> <!--- 1 column under fleur-de-lis-->
            
            <div class="col-span-2 lg:col-span-3 text-xl md:text-3xl antialiased font-semibold mb-6">Previous Clients</div>
            <div>
                 <a href="https://www.lacma.org" target="_blank"><img src="@/assets/images/lacma-logo.png" alt="Los Angeles County Museum of Art" /></a>
            </div>
            <div>
                <a href="https://biddyco.com/" target="_blank"><img src="@/assets/images/biddyco.webp" alt="BiddyCo Social Media Advertising" /></a>
            </div>
            <div>
                <a href="https://www.thesignalfactory.com" target="_blank"><img src="@/assets/images/signalfactory.png" alt="The Signal Factory" /></a>
            </div>
            <div class="mb-2">
             <a href="https://fcbnorthamerica.com/" target="_blank"><img src="@/assets/images/FCBNorthAmerica.png" alt="FCB North America" /></a>
            </div>
            <div>
              <a href="https://myredkite.com/" target="_blank"><img src="@/assets/images/redkite_logo.png" alt="My Red Kite Financial Aid" /></a>
            </div>
            <div>
                <a href="https://www.successacademies.org/" target="_blank"><img src="@/assets/images/sasc-logo.png" alt="Success Academy Charter Schools" /></a>
            </div>
            <div>
                <a href="https://www.austinplayhouse.com" target="_blank"><img src="@/assets/images/austinPlayhouse.png" alt="Austin Playhouse"/></a>
            </div>
            <div>
             <a href="https://www.ayso.org" target="_blank"><img src="@/assets/images/AYSO.png" alt="American Youth Soccer Organization" /></a>
            </div>
            <div>
                <a href="https://www.inleague.io" target="_blank"><img src="@/assets/images/inleague.png" alt="inLeague Design &amp; Development" /></a>
            </div>
            <div>
                <a href="https://www.wssl.org" target="_blank"><img src="https://www.inleague.io/sites/inleague/assets/Image/wssl-logo.png" alt="West Side Soccer League" /></a>
            </div>
            <div>
                <a href="https://www.ayso300.org" target="_blank"><img src="https://ayso300.org/wp-content/uploads/2023/11/UNITED_Crest-For-White-Background@3x.png" alt="AYSO Region 300 - United" /></a>
            </div>
          </div>
      </div>
      <div class="basis-1/2 items-center justify-center w-full md:w-5/12 h-[70rem] w-[46rem] md:-ml-8 lg:ml-8 2xl:ml-42 z-10 hidden sm:block">
        <img class="max-h-[70rem] max-w-[56rem] ] -ml-36 h-[35rem] w-[28rem] sm:h-[50rem] sm:w-[40rem] ml-10 -mt-32 sm:mt-12 lg:h-[55rem] lg:w-[44rem] lg:mt-16 xl:h-[68rem] xl:w-[55rem] lg:-ml-8" src="@/assets/images/svg/samuel.svg" alt="Artist's Rendering of Samuel Knowlton" />
      </div>
    </div>
</div>
</template>

<script>
import HeaderComponent from "../components/HeaderComponent.vue";
import DarcyHeader from "@/components/DarcyHeader.vue";
import BottomRightTriangles from "@/components/BottomRightTriangles.vue";
export default {
  name: 'Clients-View',
  components: {
    HeaderComponent,
      DarcyHeader,
      BottomRightTriangles
  }
}
</script>