
<template>
    <a :href="href" target="_blank" :class="classText + ' social-media-button'" :title="title"><font-awesome-icon :icon="icon" :size="size" /></a>
</template>

<script>
export default {
    props:{
        icon:String,
        href:String,
        title:String,
        classText: {
            type: String,
            default: 'social-media-button'
        },
        size: {
            type: String,
            default: '2xl'
        }
    }
}
</script>