<template>
    <HeaderComponent />
    <div class="section flex items-center relative overflow-hidden min-h-screen px-8 py-4 z-5">
    <BottomRightTriangles />
        <div class="sm:flex w-full h-full">
            <div class="basis-5/6 ml-1 w-full">
            <!-- Page Header -->
            <DarcyHeader />
            <!-- if we had a frame image it would go here  <div class="basis-1/2 items-center justify-center w-full md:w-5/12 ml-24 z-10 block sm:hidden"> 
            <img class="max-h-[70rem] max-w-[56rem] -ml-36 h-[35rem] w-[28rem] mt-48" src="@/assets/images/svg/samuel.svg" alt="Artist's Rendering of Discount Darcy" />
            </div>-->
            <!-- Content Area: Contact Form -->
              <div class="relative isolate mt-20 sm:mt-64 lg:mt-24">
                <div class="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                  <div class="relative px-6 pb-16 pt-8 lg:static lg:px-8">
                    <div class="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                    <div class="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden  lg:w-1/2">
                        <svg class="absolute inset-0 h-full w-full" aria-hidden="true">
                        <defs>
                            <pattern id="pid" width="200" height="200" x="100%" y="-1" patternUnits="userSpaceOnUse">
                            <path d="M130 200V.5M.5 .5H200" fill="none" />
                            </pattern>
                        </defs>
                        <rect width="100%" height="100%" stroke-width="0" fill="#f4f4ec" />
                       
                        <rect width="100%" height="100%" stroke-width="0" fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
                        </svg>
                    </div>
                    <h2 class="text-3xl font-bold tracking-tight text-gray-900">Get in touch</h2>
                    <p class="mt-6 text-lg leading-8 text-gray-600">Voiceover. Creative consulting. Adding spice to your copy. Officiating your wedding.</p>
                    <p class="mt-6 text-lg leading-8 text-gray-600">Sam and his team can be reached from the form on this page, or the email address and phone number below.</p>
                    <dl class="mt-10 space-y-4 text-base leading-7 text-gray-600">
                        <div class="flex gap-x-4">
                            <dt class="flex-none">
                                <BuildingOffice2Icon class="h-7 w-6 text-gray-400" aria-hidden="true" />
                            </dt>
                            <dd>Autsin, Texas (Local &amp; Regional Hire)</dd>
                            </div>
                        <div class="flex gap-x-4">
                        <dt class="flex-none">
                            <span class="sr-only">Telephone</span>
                            <PhoneIcon class="h-7 w-6 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd><a class="hover:text-gray-900" href="tel:+1 (555) 234-5678">+1 (512) 814-8022</a></dd>
                        </div>
                        <div class="flex gap-x-4">
                            <dt class="flex-none">
                                <span class="sr-only">Email</span>
                                <EnvelopeIcon class="h-7 w-6 text-gray-400" aria-hidden="true" />
                            </dt>
                            <dd><a class="hover:text-gray-900" href="mailto:booking@samuelknowlton.com">booking@samuelknowlton.com</a></dd>
                        </div>
                    </dl>
                </div>
                </div>
                <div class="invisible hidden font-semibold" id="thankYou">Your message has been sent. Thank you!</div>
                <form id="msgForm" ref="values" @submit.prevent="sendEmail" class="px-6 pb-16 pt-4 lg:px-8">
                    <div class="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                    <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div class="sm:col-span-2">
                        <label for="name" class="block text-sm font-semibold leading-6 text-gray-900">Name</label>
                        <div class="mt-2.5">
                            <input type="text" name="name" id="name" v-model="name" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                        </div>
                        </div>
                        <div class="sm:col-span-2">
                        <label for="email" class="block text-sm font-semibold leading-6 text-gray-900">Email</label>
                        <div class="mt-2.5">
                            <input type="email" name="email" v-model="email" id="email" autocomplete="email" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                        </div>
                        </div>
                        <div class="sm:col-span-2">
                        <label for="phoneNumber" class="block text-sm font-semibold leading-6 text-gray-900">Phone Number</label>
                        <div class="mt-2.5">
                            <input type="text" name="phoneNumber" v-model="phoneNumber" id="phoneNumber" autocomplete="phone" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                        </div>
                        </div>
                        
                        <div class="sm:col-span-2">
                        <label for="message" class="block text-sm font-semibold leading-6 text-gray-900">Message</label>
                        <div class="mt-2.5">
                            <textarea name="message" id="message" v-model="message" rows="4" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                        </div>
                        </div>
                    </div>
                    <div class="mt-8 flex justify-end">
                        <vue-recaptcha sitekey="6Le6CAwpAAAAAFrrn6F6PBQmavsSQUdbxmBUlnY6" @verify="sendEmail" :load-recaptcha-script="true">
                            <button type="submit" class="rounded-md bg-blue-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Send message</button>
                        </vue-recaptcha>
                    </div>
                    </div>
                </form>
                </div>
                <div class="mx-auto -mt-4">
                    <h2 class="text-3xl font-bold tracking-tight text-gray-900">Payment</h2>
                    <p class="mt-6 text-lg leading-8 text-gray-600">Most projects are invoiced. For one-time payments or smaller projects, Venmo and Paypal are accepted.</p>
                
                    <a href="https://paypal.me/MordantWastrel" target="_blank"><img src="@/assets/images/paypal.png" /></a>
                    <a href="https://account.venmo.com/pay?recipients=MordantWastrel"><img src="https://ixd.prattsi.org/wp-content/uploads/2021/02/venmo-logo.png" alt="Pay with Venmo" /></a>
                </div>
         </div>
       
      </div>
    </div>
  </div>
</template>
<script>
import HeaderComponent from "../components/HeaderComponent.vue";
import DarcyHeader from "@/components/DarcyHeader.vue";
import BottomRightTriangles from "@/components/BottomRightTriangles.vue";
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/vue/24/outline'
import { VueRecaptcha } from 'vue-recaptcha';
import emailjs from 'emailjs-com';
export default {
   name: 'Contact-View',
   components: {
        HeaderComponent,
        DarcyHeader,
        BottomRightTriangles,
        BuildingOffice2Icon,
        EnvelopeIcon,
        PhoneIcon,
        VueRecaptcha
   },
   methods: {
    onEvent() {
        // when you need a reCAPTCHA challenge
       this.$refs.recaptcha.execute();
      },
    sendEmail() {
      emailjs
        .sendForm(
          "darcy_gmail",
          "dd_template",
          "#msgForm",
          "6NRZ7Ja0yzTKXV4d3"
        )
        .then(
          (result) => {
            console.log(
              "You have successfully submitted your message.",
              result.text
            );
            document.getElementById('msgForm').hidden = true;
            document.getElementById('thankYou').style.display = 'block';
            document.getElementById('thankYou').hidden = false;
            document.getElementById('thankYou').style.visibility = 'visible';
          },
          (error) => {
            console.log(
              "This form failed to submit, please kindly check your internet connection.",
              error.text
            );
          }
        );
    }
   },
   data() {
    return {
        name: '',
        email: '',
        phoneNumber: '',
        message: ''
    }
   }
}

</script>