<style scoped>
.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}
</style>

<template>
  <HeaderComponent />
  <div class="section flex items-center relative overflow-hidden min-h-screen px-8 py-4 z-5">
    <BottomRightTriangles />
    <div class="sm:flex w-full h-full">
      <div class="basis-1/2 ml-1 w-full">
        <!-- Page Header -->
        <DarcyHeader />
        <div class="basis-1/2 items-center justify-center w-full pt-6 ml-32 z-10 block xs2:-mt-32 xs2:ml-52 sm:hidden"> <!--- Frame image only below small breakpoint -->
              <img class="max-h-[70rem] max-w-[56rem] -ml-36 h-[30rem] w-[24rem] mt-40" src="@/assets/images/SamDarcy.gif" alt="Samuel Knowlton" />
         </div>
        <!-- Content Area: Demos and Intro Text -->
        <div class="flex flex-col sm:flex-row w-full max-w-[64rem] -mt-4 sm:mt-72 lg:mt-28 ml-2 sm:ml-8 3xl:mt-36">
          <div class="basis-1/2">
            <RecordComponent audioSrc="/assets/voices/SamuelKnowltonDemo.mp3" name="Commercial Demo" />
          </div>
          <div class="basis-1/2 antialiased mt-4">
            <p><span class="font-semibold">Dad Next Door.</span> <span class="font-semibold">Arch</span> and <span class="font-semibold">Aristocratic</span>. From <span class="font-semibold">Fabulous Fop</span> to <span class="font-semibold">Diabolical Villainy</span>, Sam is your partner in the creative process for voiceover, user-generated content, copy, and character development. A twenty year veteran performer and entrepreneur, Sam wraps the delight of discovery in the Sandwich of Professionalism, whether your project is commercial, educational, or entertainment.</p>
            <p class="mt-4">Sir Derek Jacobi described the purpose of voice in performance "to express feeling and to lift the words off the page and inhabit them, and give them a soul, and a sense of feeling and a life."  It's not about booming monologues in verse: it's finding the same joy in articulation in everything from narrating a video to Henry V to an exciting new interest rate in a 6 month certificate of deposit at the bank. If it's not at least a little fun ... why bother? </p>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row w-1/2 ml-4 md:ml-0 3xl:mt-36">
          <div class="embla mt-8 sm:-mt-56" ref="emblaNode">
            <div class="embla__container">
              <div class="embla__slide">
                <a href="https://www.biddyco.com" target="_blank"><img class="embla__slide__img" src="@/assets/images/biddyco.webp" alt="BiddyCo Social Media" /></a>
              </div>
              <div class="embla__slide">
                <a href="https://www.lacma.org" target="_blank"><img class="embla__slide__img" src="@/assets/images/lacma-logo.png" alt="Los Angeles County Museum of Art" /></a>
              </div>
              <div class="embla__slide">
                <a href="https://www.thesignalfactory.com" target="_blank"><img class="embla__slide__img" src="@/assets/images/signalfactory.png" alt="The Signal Factory" /></a>
              </div>
              <div class="embla__slide">
                <a href="https://fcbnorthamerica.com/" target="_blank"><img class="embla__slide__img" src="@/assets/images/FCBNorthAmerica.png" alt="FCB North America" /></a>
              </div>
              <div class="embla__slide">
                <a href="https://myredkite.com/" target="_blank"><img class="embla__slide__img" src="@/assets/images/redkite_logo.png" alt="My Red Kite" /></a>
              </div>
              <div class="embla__slide">
                <a href="https://www.austinplayhouse.com/" target="_blank"><img class="embla__slide__img" src="@/assets/images/austinPlayhouse.png" alt="Austin Playhouse" /></a>
              </div>
              <div class="embla__slide">
                <a href="https://www.successacademies.org/" target="_blank"><img class="embla__slide__img" src="@/assets/images/sasc-logo.png" alt="Success Academy Charter Schools" /></a>
              </div>
              <div class="embla__slide">
                <a href="https://www.ayso.org" target="_blank"><img class="embla__slide__img" src="@/assets/images/AYSO.png" alt="American Youth Soccer Organization" /></a>
              </div>
              <div class="embla__slide">
                <a href="https://www.inleague.io" target="_blank"><img class="embla__slide__img" src="@/assets/images/inleague.png" alt="inLeague Design &amp; Development" /></a>
              </div>
            </div>
          </div>
        </div>
     </div>
     <div class="basis-1/2 items-center justify-center w-full md:w-5/12 h-[70rem] w-[46rem] lg:ml-8 2xl:ml-42 z-10 hidden sm:block">
          <img class="max-h-[55rem] max-w-[44rem] h-[37rem] w-[30rem] sm:ml-0 mt-20 md:h-[45rem] md:w-[36rem] lg:h-[55rem] lg:w-[44rem] xl:h-[68rem] xl:w-[55rem] lg:ml-10 lg:mt-16" src="@/assets/images/SamDarcy.gif" alt="Absolutely an Accurate Picture of the Pemberley Recording Studio" />
      </div>
    </div>
  </div>
</template>

<script>
    import HeaderComponent from "../components/HeaderComponent.vue";
    import DarcyHeader from "@/components/DarcyHeader.vue";
    import BottomRightTriangles from "@/components/BottomRightTriangles.vue";
    import RecordComponent from "@/components/sectionComponents/RecordComponent.vue";
    import { watchEffect } from 'vue'
    import emblaCarouselVue from 'embla-carousel-vue'
    import Autoplay from 'embla-carousel-autoplay'
    export default {
    name : 'Home-View',
    setup() {
      const autoplayOptions = {
        jump : false,
        stopOnInteraction : false
      }
      const [emblaNode, emblaApi] = emblaCarouselVue({ loop: false }, [Autoplay( autoplayOptions )])

      watchEffect(() => {
        if (emblaApi.value) {
         // console.log(emblaApi.value.slideNodes()) // Access API
        }
      })

      return { emblaNode, emblaApi }
    },
    components: {
        HeaderComponent,
        RecordComponent,
        DarcyHeader,
       BottomRightTriangles
    }
}
</script>