<template>
    <div class="mt-12 w-full">
      <h4 class="audio-name">{{ name }}</h4>
      <div class="audio-player">
        <audio ref="audioo" :src="audioSrc" @timeupdate="updateSeekBar" @ended="onAudioEnded"></audio>
        <div ref="PlayButton" class="play-button" @click="togglePlay"></div>
        <div ref="seekBar" class="seek-bar" @click="seek">
          <div class="seek-bar-fill" :style="{ width: fillWidth }"></div>
          <div class="download-button" @click="downloadAudio"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      name: String,
      audioSrc: String,
    },
    data() {
      return {
        isPlaying: false,
        fillWidth: "0%",
      };
    },
    methods: {
      togglePlay() {
        const audio = this.$refs.audioo;
        console.log(audio);
        if (audio.paused) {
          audio.play();
          this.$refs.PlayButton.style.backgroundImage = 'url("https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-pause-512.png")';
          this.isPlaying = true;
        } else {
          audio.pause();
          this.$refs.PlayButton.style.backgroundImage = 'url("https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-play-512.png")';
          this.isPlaying = false;
        }
      },
      downloadAudio() {
        const link = document.createElement('a');
        link.href = this.audioSrc;
        link.download = this.name || 'audio-file';
        link.click();
      },
          updateSeekBar() {
        const audio = this.$refs.audioo;
        const seekBarFill = this.$refs.seekBar.querySelector(".seek-bar-fill");
        const fillWidth = (audio.currentTime / audio.duration) * 100 + "%";
        seekBarFill.style.width = fillWidth;
      },
      seek(event) {
        const audio = this.$refs.audioo;
        const seekBarFill = this.$refs.seekBar.querySelector(".seek-bar-fill");
        const seekBarWidth = this.$refs.seekBar.offsetWidth;
        const clickX = event.clientX - seekBarFill.getBoundingClientRect().left;
        const seekTime = (clickX / seekBarWidth) * audio.duration;
        audio.currentTime = seekTime;
      },
      onAudioEnded() {
        this.isPlaying = false;
        this.$refs.PlayButton.style.backgroundImage = 'url("https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-play-512.png")';

      },
    },
  };
  </script>
  