import { createApp } from "vue";
import App from "./App.vue";
import router from './router'
import VueGtag from "vue-gtag";
import '../public/assets/css/audio_player.css';
import './style.css'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core' // eslint-disable-line no-unused-vars
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// import specific icons 
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

// add icons to the library 
library.add( faFacebookF, faTwitter, faInstagram )

createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).use(VueGtag, { appName: 'Discount Darcy', pageTrackerScreenviewEnabled: true, config: { id: "G-VT0BQPHEMG" }, router } ).mount("#app");
